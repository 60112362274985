@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet');
@tailwind base;
@tailwind components;
@tailwind utilities;


header ul li{
  @apply px-2;
}
.bg-primary{
  background: rgb(0,0,0);
}
.bg-secondary{
  background:rgb(0,0,0);
}
.mobile-nav{
  @apply fixed top-11 left-0 bg-customBlue w-full h-1/5;
}
.mobile-nav li{
  @apply w-full h-full text-center border-b-2;

}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

#typedText {
  overflow: hidden;
  border-right: .15em solid black;
  white-space: nowrap;
  animation: typing 3s steps(40, end);
}



.shooting-star-container {
  position: relative;
  width: 100%;
  height: 100px; /* Adjust height as needed */
  overflow: hidden;
}

.shooting-star {
  position: absolute;
  top: 0;
  left: -100px;
  animation: shooting-star-animation 3s linear infinite; /* Adjust duration and timing function as needed */
}

.star {
  width: 15px; /* Adjust size of the star */
  height: 15px; /* Adjust size of the star */
  background-color: #ffffff; /* Adjust star color */
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%); /* Create a triangle shape */
}

@keyframes shooting-star-animation {
  to {
    transform: translateX(120%);
  }
}

